<template>
    <div>
        <portal to="content-header-left">
            <activix-reload :loading="$wait.is('fetching.accounts')" @click="fetchAccounts" />
        </portal>

        <div class="box" :class="{ loading: $wait.is('fetching.accounts') }">
            <div class="box-header | h-auto p-6 flex justify-between">
                <router-link class="btn btn-primary" :to="{ name: 'accounts.store' }">
                    <icon name="bold/add" class="mr-2 text-sm" />
                    {{ $t('general.add') }}
                </router-link>
                <input
                    class="form-control w-auto"
                    type="text"
                    :placeholder="$t('search.search')"
                    v-model="tableState.searchToken"
                />
            </div>
            <div class="box-body | p-0">
                <el-table
                    class="w-full border-t-2 border-b"
                    :border="true"
                    :data="paginatedAccounts"
                    :default-sort="{ prop: 'name', order: 'ascending' }"
                    :stripe="true"
                    @sort-change="updateTableSorting"
                >
                    <el-table-column>
                        <el-table-column
                            prop="name"
                            header-align="center"
                            min-width="160"
                            :label="$t('users.edit.name')"
                            :sortable="true"
                            :resizable="false"
                        >
                            <template slot-scope="scope">
                                <a @click="onClickAccount(scope.row.id)">
                                    {{ scope.row.name }}
                                </a>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            prop="phone"
                            min-width="120"
                            :label="$t('general.phone')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.formatedPhone }}
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column header-align="center" :label="$t('general.services')">
                        <el-table-column
                            prop="leadxpress"
                            align="center"
                            min-width="100"
                            :label="$t('dashboards.leadXpress')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.leadXpress')" v-if="scope.row.leadxpress">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="webboost"
                            align="center"
                            min-width="90"
                            :label="$t('dashboards.webBoost')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.webBoost')" v-if="scope.row.webboost">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="walk_in"
                            align="center"
                            :label="$t('dashboards.walkIn')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.walkIn')" v-if="scope.row.walk_in">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="phone_up"
                            align="center"
                            min-width="90"
                            :label="$t('dashboards.phoneUp')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.phoneUp')" v-if="scope.row.phone_up">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="loyalty"
                            align="center"
                            :label="$t('dashboards.loyalty')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.loyalty')" v-if="scope.row.loyalty">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="renewal"
                            align="center"
                            min-width="130"
                            :label="$t('dashboards.renewal')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.renewal')" v-if="scope.row.renewal">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="sale_table"
                            align="center"
                            min-width="100"
                            :label="$t('dashboards.saleTable')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.saleTable')" v-if="scope.row.sale_table">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="service"
                            align="center"
                            :label="$t('dashboards.service')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('dashboards.service')" v-if="scope.row.service">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column>
                        <el-table-column
                            prop="bdc_advisor"
                            align="center"
                            :label="$t('general.bdc')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('accounts.edit.bdcAgent')" v-if="scope.row.bdc_advisor">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="account_manager"
                            align="center"
                            min-width="80"
                            :label="$t('general.accountManager')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip
                                    :content="$t('general.accountManager')"
                                    v-if="scope.row.account_manager"
                                >
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="active"
                            align="center"
                            label-class-name="whitespace-nowrap"
                            :label="$t('general.active')"
                            :sortable="true"
                        >
                            <template slot-scope="scope">
                                <activix-tooltip :content="$t('general.active')" v-if="scope.row.active">
                                    <icon name="regular/check-double" />
                                </activix-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="activation_date"
                            align="center"
                            min-width="100"
                            :label="$t('general.activated_at')"
                            :sortable="true"
                            :formatter="handleTableDateTimeFormat"
                        />
                        <el-table-column width="80" align="center">
                            <template slot-scope="scope">
                                <a class="link-grey" @click="onClickUserList(scope.row.id)">
                                    <activix-tooltip :content="$t('titles.users.index')">
                                        <icon name="regular/multiple-neutral-2" />
                                    </activix-tooltip>
                                </a>
                                <a
                                    class="link-grey ml-3 | hover:text-red-500"
                                    @click="onDelete(scope.row.id)"
                                    v-if="canDeleteAccount"
                                >
                                    <activix-tooltip :content="$t('general.delete')">
                                        <icon :name="$icons.trash" />
                                    </activix-tooltip>
                                </a>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <el-pagination
                    class="p-6"
                    :layout="paginationLayout"
                    :background="true"
                    :current-page.sync="tableState.currentPage"
                    :page-size.sync="tableState.perPage"
                    :page-sizes="tableState.pageOptions"
                    :pager-count="5"
                    :total="filteredSortedAccounts.length"
                    @size-change="updateTablePerPage"
                />
            </div>
        </div>

        <activix-confirm-modal
            type="warning"
            :content="$t('accounts.edit.deleteAccount')"
            :opened.sync="deleteModal.opened"
            @approve="deleteAccount"
            @closed="deleteModal.accountId = null"
        />
    </div>
</template>

<script>
    import { orderBy } from 'lodash-es';
    import { mapActions, mapState } from 'pinia';
    import { gql } from 'apollo-boost';
    import Account from '@/entities/Account';
    import TrackView from '../../mixins/TrackView.js';
    import DataTableMixin from '../../mixins/DataTable.js';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        name: 'AccountsList',

        mixins: [TrackView, DataTableMixin],

        apollo: {
            accounts: {
                query: gql`query accounts {
                        accounts {
                            id
                            name
                            phone
                            leadxpress
                            webboost
                            walk_in
                            phone_up
                            loyalty
                            renewal
                            sale_table
                            service
                            bdc_advisor
                            account_manager
                            active
                            activation_date
                        }
                }`,
                result({ data }) {
                    this.accounts = data.accounts.map(account => new Account(account));
                },
            },
        },
        data() {
            return {
                accounts: [],
                deleteModal: {
                    opened: false,
                    accountId: null,
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'configs']),

            canDeleteAccount() {
                return this.authUser.isProgrammer();
            },

            filteredSortedAccounts() {
                const searchedData = this.searchData(this.tableState.searchToken, this.accounts);

                return orderBy(searchedData, [this.tableState.sorting.column], [this.tableState.sorting.order]);
            },

            paginatedAccounts() {
                return this.filteredSortedAccounts.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },
        },

        methods: {
            ...mapActions(useContextStore, ['setContextAccountAction']),

            async fetchAccounts() {
                this.$wait.start('fetching.accounts');

                try {
                    await this.$apollo.queries.accounts.refetch();

                    this.$wait.end('fetching.accounts');
                } catch (error) {
                    this.$wait.end('fetching.accounts');
                    this.$notify.error(this.$t('accounts.alerts.index.error'));

                    throw error;
                }
            },

            onDelete(id) {
                this.deleteModal.opened = true;
                this.deleteModal.accountId = id;
            },

            async deleteAccount() {
                const id = this.deleteModal.accountId;

                if (!id) {
                    return;
                }

                try {
                    await this.$api.accounts.destroy(id);

                    this.$notify.success(this.$t('accounts.alerts.destroy.success'));
                    this.fetchAccounts();
                } catch (error) {
                    this.$notify.error(this.$t('accounts.alerts.destroy.error'));

                    throw error;
                }
            },

            async onClickUserList(accountId) {
                await this.setContextAccountAction(accountId);

                this.$router.replace({ name: 'users.index', query: { accountId } });
            },

            async onClickAccount(accountId) {
                await this.setContextAccountAction(accountId);

                this.$router.replace({ name: 'accounts.update', params: { id: accountId } });
            },
        },

        created() {
            this.fetchAccounts();
        },
    };
</script>
